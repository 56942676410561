@tailwind base;
@tailwind components;
@tailwind utilities;

.orbitron {
  font-family: 'Orbitron', sans-serif;
}

body {
  color: white;
  background-color: #0c001d;
  font-family: 'Open Sans', sans-serif;
}

a:hover {
  text-decoration: underline;
}

.site-body {
  /* background-image: url('./assets/bg-gradient-1@3x.png'); */
  background-blend-mode: hard-light;
  /* height: 1000px; */
  background-image: radial-gradient(
    circle at 53% -87%,
    #4b02b8,
    rgba(147, 75, 255, 0) 70%
  );
}

.content-width {
  max-width: 1660px;
  margin: auto;
}

.join-banner {
  background-image: url('./assets/join-discord/bg-3-blurred@2x.jpg');
  width: 100%;
  height: 100%;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.join-banner-mobile {
  background-image: url('./assets/join-discord/bg-3-blurred@2x.jpg');
  width: 100%;
  height: 340px;
  background-size: cover;
  background-position: 50%;
}

.collection-banner {
  background-image: url('./assets/collection-bg.png');
  width: 100%;
  height: 100%;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.collection-banner-mobile {
  background-image: url('./assets/collection-bg.png');
  width: 100%;
  height: 340px;
  background-size: cover;
  background-position: 50%;
}

.button-color {
  background-color: #27005d;
  transition: 0.05s;
}

.button-color:hover {
  box-shadow: 0 0 21px 0 #5dff4b;
  border: solid 1px #5dff4b;
  background-image: linear-gradient(to bottom, #5dff4b, #0b6b00 97%);
  transition: 0.05s;
}

.button-color:active {
  box-shadow: 0 0 21px 0 #6600f5;
  border: solid 1px #7f29ff;
  background-image: linear-gradient(to bottom, #934bff 0%, #6600f5 98%);
  transition: 0.05s;
}

.button-color-invert {
  background-color: #5dff4b;
  transition: 0.05s;
}

.button-color-invert:hover {
  box-shadow: 0 0 21px 0 #5dff4b;
  border: solid 1px #5dff4b;
  background-image: linear-gradient(to bottom, #934bff, #6600f5 97%);
  transition: 0.05s;
}

.button-color-invert:active {
  box-shadow: 0 0 21px 0 #6600f5;
  border: solid 1px #7f29ff;
  background-image: linear-gradient(to bottom, #934bff 0%, #6600f5 98%);
  transition: 0.05s;
}

.arrow-link {
  color: #ac75ff;
}

.arrow-link:hover {
  color: #5dff4b;
  transition: 0.1s;
}

.border-color {
  border-color: #ac75ff;
}

.icon-placeholder {
  background-color: #1d0044;
  border-color: #4600a9;
}

.footer {
  background-image: linear-gradient(to bottom, #14002e, #1d0044);
}

.footer-header {
  color: #ac75ff;
}

.mw {
  max-width: 940px;
  margin: 0 auto;
}

.menu-bg {
  background-color: #27005d;
}

.rect-faq {
  width: 138px;
  height: 10px;
  background-color: #6600f5;
}

.rect-faq-mobile {
  width: 90px;
  height: 5px;
  background-color: #6600f5;
}

.rect-collections {
  width: 170px;
  height: 10px;
  background-color: #6600f5;
}

.rect-collections-mobile {
  width: 69px;
  height: 5px;
  background-color: #6600f5;
}

.faq-border {
  border-bottom: 1px solid #441386;
}

.link-purp {
  color: #ac75ff;
}

.link-purp:hover {
  color: #5dff4b;
}

.partners-bg {
  background-image: url('./assets/partnerships/intro-bg@2x.jpg');
  background-size: cover;
  background-position: center;
}

.purple-text {
  color: #ac75ff;
}

.green-text {
  color: #5dff4b;
}

.welcome-bg {
  background-image: url('./assets/welcome/intro-bg@2x.png');
  background-size: cover;
  background-position: top;
}

.rect-welc {
  width: 108px;
  height: 3px;
  background-color: #ac75ff;
}

.no-underline {
  text-decoration: none !important;
}

.square-bg {
  background-color: rgba(29, 0, 68, 0.6);
}

.glow-button:hover {
  filter: drop-shadow(0px 0px 10px #ac75ff);
  transition: 0.1s;
}

.glow-scale-button:hover {
  filter: drop-shadow(0px 0px 10px #ac75ff);
  transform: scale(1.2, 1.2);
  transition: 0.1s;
}

.scale-button:hover {
  transform: scale(1.2, 1.2);
  transition: 0.1s;
}

.slide-overlay {
  height: 360px;
  background: #1d0044;
  background-image: radial-gradient(black 33%, transparent 33%);
  background-size: 4px 4px;
}

h1 {
  font-family: Orbitron;
  font-size: 44px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: #fff;
}

.H2-Alternate {
  font-family: Orbitron;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 2.5px;
  color: #fff;
}

.Dash-Page-Title {
  font-family: Orbitron;
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
}

h2 {
  font-family: Orbitron;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.H1---Mobile {
  font-family: Orbitron;
  font-size: 28px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #fff;
}

.H2-Alternate---Mobile {
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 2px;
  color: #fff;
}

h3 {
  font-family: OpenSans;
  font-size: 26px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #fff;
}

.H2---Mobile {
  font-family: Orbitron;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #fff;
}

.ParagraphFeature-Paragraph {
  /* font-family: OpenSans; */
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #fff;
}

.ParagraphFeature {
  font-family: OpenSans;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: #fff;
}

.H3---Mobile {
  font-family: OpenSans;
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #fff;
}

h4 {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #fff;
}

.ParagraphFeature---Mobile {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.H4---Mobile {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #fff;
}

.ParagraphSemibold {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.ParagraphFeature-Paragraph---Mobile {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #fff;
}

.ParagraphDefault {
  font-family: OpenSans;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.ParagraphSemibold-Smaller {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.Iconbox-Label {
  font-family: Orbitron;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
}

.MenuHover {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #fff;
}

.MenuDefault {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #fff;
}

.MenuActive {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--purple-1);
}

.ParagraphDefault---Mobile {
  font-family: OpenSans;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

h5 {
  font-family: Orbitron;
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--purple-2);
}

.ButtonsText-OnlyLabel-Hover {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--purple-1);
}

.ButtonsText-OnlyLabel {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--purple-2);
}

.ButtonsPrimary-Label {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  text-align: center;
  color: #fff;
}

.ButtonsIcon-Label {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #fff;
}

caption {
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

h6 {
  font-family: Orbitron;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 1.5px;
  color: var(--purple-1);
}

.MenuFooter {
  font-family: OpenSans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #fff;
}

.gradient-1 {
  background: linear-gradient(
    180deg,
    rgba(17, 2, 52, 1) 0%,
    rgba(38, 1, 59, 1) 100%
  );
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 206px;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
}

.track > h1 {
  margin: 20px 0;
  font-size: 8rem;
  font-family: Antonio;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #f4955c;
  text-transform: uppercase;
}

.fade-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.fade-container::before,
.fade-container::after {
  content: '';
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  pointer-events: none;
  z-index: 2;
}

.fade-container::before {
  left: 0;
  background: linear-gradient(to right, #21024a 0%, transparent 100%);
}

.fade-container::after {
  right: 0;
  background: linear-gradient(to left, #21024a 0%, transparent 100%);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.carousel::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.carousel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
